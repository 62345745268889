import React, { useEffect, useState, useContext } from "react";
import { ThemeContext, PatientContext, InputVisibleContext, PreferredLanguageContext } from "../lib/main-context";
import { SALLIE_DASHBOARD, VERIFY_EMAIL, TOGGLE_MESSAGES, MAILBOX, TOGGLE_ALERTS } from "../constants/DashboardTranslation";

import { useHistory, useLocation } from "react-router-dom";
import "react-day-picker/lib/style.css";
import { Row, Col, Alert } from "react-bootstrap";

//components
import Sallie from "./Sallie";
import FeelingToday from "./DashboardComponents/FeelingToday";
import Calendar from "./DashboardComponents/Calendar";
import EditNotification from "./DashboardComponents/EditNotification";
import SymptomTracker from "./DashboardComponents/SymptomTracker";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import AdditionalNote from "./DashboardComponents/AdditionalNote";
import TriggerSelector from "./DashboardComponents/TriggerSelector";
import ThemeChanger from "./ThemeChanger";
import AddSymptoms from "./DashboardComponents/AddSymptoms";
import Charts from "./DashboardComponents/Charts";
import LoadingSpinner from "./LoadingSpinner";
import CurrentPatient from "./DashboardComponents/CurrentPatient";
import OnOffButtonMessages from "./DashboardComponents/OnOffButtonMessages";
import OnOffButtonAlert from "./DashboardComponents/OnOffButtonAlert";
import PatientStatus from "./DashboardComponents/PatientStatus";
import AssignmentButton from "./DashboardComponents/AssignmentButton";
import HemisphericSum from "./DashboardComponents/HemisphericSum/HemisphericSumContainer";
import TherapyTracker from "./DashboardComponents/TherapyTracker/TherapyTrackerContainer"

//lib
import customizeMessages from "../lib/customizeMessages";
import useWindowSize from "../lib/useWindowSize";
import getLoggedDataToday from "../lib/getLoggedDataToday";

//api
import { getAdditionalNote, getPartiallyTrackedDays, getTrackedDays } from "../api/TBIRequests";
import { getPatientRegistrationProgress } from "../api/TBIRequests";
import emailAlert from "../images/email-notification.svg";
import theme from "../index.scss";

const Dashboard = ({
  user,
  loadTrackedData,
  loadingTrackedData,
  trackedData,
  errorLoadingTrackedData,
  topSymptoms,
  setTopSymptoms,
  loadingTopSymptoms,
  loadingFeelingToday,
  doneSimpleTracking,
  setDoneSimpleTracking,
  loadingTopTrigger,
  topTrigger,
  errorLoadingTopTrigger,
  loadTrigger,
  messages,
  setCaregiverList,
  setPatientList,
  patientList,
  caregiverList,
  onOffButtonMessagesStatus,
  setOnOffButtonMessagesStatus,
  inputVisible,
  setInputVisible,
}) => {
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);
  const { dark } = useContext(ThemeContext);

  const [width] = useWindowSize();
  const history = useHistory();
  const location = useLocation();

  // tied to calendar components
  const [date, setDate] = useState(new Date());

  // start with the 0 index of the user's top symptoms and update as needed
  const [currentTrackingSymptom, setCurrentTrackingSymptom] = useState(0);

  // initialized to one because we dont use it until at least one symptom
  // has been tracked
  const [currentTrackingStep, setCurrentTrackingStep] = useState(0);
  const [additionalNotes, setAdditionalNotes] = useState([]);
  const [showPopUp, setShowPopUp] = useState(true);
  const [status, setStatus] = useState("Active");
  const [firstUnfinishedStep, setFirstUnfinishedStep] = useState("");

  const [trackedDays, setTrackedDays] = useState([]);
  const [partiallyTrackedDays, setPartiallyTrackedDays] = useState([]);
  const [untrackedDays, setUntrackedDays] = useState([]);

  const [skippedUnloggedSymptoms, setSkippedUnloggedSymptoms] = useState(0);

  const checkUnreadMessagesPresent = (messages) => {
    const isProvider = user.userType === 'provider';
    const isPatient = user.userType !== 'provider';

    return (
      messages &&
      messages.some((message) => {
        const belongsToProvider = message.providerId === user.userId;
        const isFromDirection = message.direction === 'from';
        const isToDirection = message.direction === 'to';
        const hasBeenRead = message.hasBeenRead;

        // Logic for showing Mark as Read button
        const showMarkAsReadButton =
          // Patient: Message is from a provider, and unread
          (!hasBeenRead && isPatient && isFromDirection) ||
          // Provider: Message is to the provider, unread, and has matching providerId
          (!hasBeenRead && isProvider && isToDirection && belongsToProvider);

        return showMarkAsReadButton;
      })
    );
  };

  const unreadMessagesPresent = checkUnreadMessagesPresent(messages);

  //add more symptoms,if there's no tracking factors,then refresh the page
  if (location?.state?.fromPatientDashboard == true) {
    window.location.reload();
  }
  const handleDayClick = (day, { disabled }) => {
    if (disabled) {
      return;
    }
    setDate(day);
  };

  useEffect(() => {
    let isMounted = true; // Add a flag to track if the component is mounted
    // Use the isMounted flag in your asynchronous operation
    getPatientRegistrationProgress(patient?.patientId)
      .then(({ data }) => {
        if (isMounted) {
          // Check if the component is still mounted before updating state
          setFirstUnfinishedStep(data);
        }
      })
      .catch((e) => console.log("getPatientRegistrationProgress", e));

    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
    };
  }, [patient]);

  if (patient && firstUnfinishedStep.firstFalseStep != "finished") {
    if (firstUnfinishedStep.firstFalseStep == "step1") {
      history.push("/BasicInfo");
    }
    if (firstUnfinishedStep.firstFalseStep == "step2" ||
      firstUnfinishedStep.firstFalseStep == "step3" ||
      firstUnfinishedStep.firstFalseStep == "step4") {
      history.push("/laterSymptoms", {
        fromPatientControl: true, // add this to skip step7b-Notification preference
      });
    }
    if (firstUnfinishedStep.firstFalseStep == "step5") {
      history.push("/laterSymptoms", {
        fromPatientControl: true, // add this to skip step7b-Notification preference
      }); //do not push to selectTopSymptoms because it needs info from step4
    }
    if (firstUnfinishedStep.firstFalseStep == "step6") {
      history.push("/selectTrigger", {
        fromPatientControl: true, // add this to skip step7b-Notification preference
      });
    }
    if (firstUnfinishedStep.firstFalseStep == "step7") {
      history.push("/notificationPrefs", {
        fromPatientControl: true, // add this to skip step7b-Notification preference
      });
    }
  }

  useEffect(() => {
    let isMounted = true; // Add a flag to track if the component is mounted
    // Use the isMounted flag in your asynchronous operation
    getAdditionalNote(patient?.patientId)
      .then(({ data }) => {
        if (isMounted) {
          // Check if the component is still mounted before updating state
          setAdditionalNotes(data);
        }
      })
      .catch((e) => console.log("getAdditionalNotesError", e));

    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
    };
  }, [patient]);

  useEffect(() => {
    let isMounted = true; // add this variable to keep track of component's mounted status
    if (topSymptoms && topSymptoms.length > 0 && trackedData) {
      const loggedSymptomToday = getLoggedDataToday(
        trackedData,
        "medical",
        date
      );
      if (loggedSymptomToday.length === 0 && isMounted) {
        // check if component is still mounted before updating state
        setCurrentTrackingSymptom(topSymptoms[0]);
        setCurrentTrackingStep(0);
        setSkippedUnloggedSymptoms(0)
      } else if (isMounted) {
        // check if component is still mounted before calling saveTrackingStep()
        saveTrackingStep();
      }
    }
    // Cleanup function to handle unmounting of the component
    return () => {
      isMounted = false; // update the isMounted variable to reflect that component is unmounted
    };
  }, [history, topSymptoms, trackedData, date]);

  useEffect(() => {
    // TODO: This is a bandaid for resetting on date change, this should be removed in the future. (When doing so, re-add date to the previous useEffect)
    if (topSymptoms && topSymptoms.length > 0 && trackedData) {
      setCurrentTrackingSymptom(topSymptoms[0]);
      setCurrentTrackingStep(0);
      setSkippedUnloggedSymptoms(0);
      prepareNextSymptomTrackingStep(0);
    }
  }, [date]);

  useEffect(() => {
    if ((user.userType == "caregiver" || user.userType == "provider") && !patient) {
      history.push("/patientControl");
    }
  }, []);

  function sqlToJsDate(sqlDate) {
    // Example value: 2025-02-18T05:00:00.000Z
    var t = sqlDate.split(/[-T:.Z]/);
    return new Date(Number(t[0]), Number(t[1]) - 1,  Number(t[2]), 0, 0, 0, 0)
  }

  // Get tracked days for calendar.
  useEffect(() => {
    getPartiallyTrackedDays({ patientId: patient.patientId }).then(({ data }) => {
      let tempTrackedDays = data.map(d => sqlToJsDate(d.symptom_date));
      setPartiallyTrackedDays(tempTrackedDays);

      getTrackedDays({ patientId: patient.patientId }).then(({ data }) => {
        const dates = data.map(d => sqlToJsDate(d.symptom_date));
        setTrackedDays(dates);
        tempTrackedDays = tempTrackedDays.concat(dates)


        tempTrackedDays.sort((a, b) => a.getTime() - b.getTime());
        let end = new Date();
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        let start = new Date(end.getFullYear(), end.getMonth(), 1);
        if (tempTrackedDays.length > 0 && tempTrackedDays[0].getTime() < start.getTime()) {
          start = tempTrackedDays[0]
        }

        let i = 0
        const tempUntrackedDays = [];
        for (const dt = new Date(start); dt < end; dt.setDate(dt.getDate() + 1)) {
          if (i < tempTrackedDays.length
            && tempTrackedDays[i].getMonth() == dt.getMonth()
            && tempTrackedDays[i].getDate() == dt.getDate()) {
            i += 1
            continue;
          }
          tempUntrackedDays.push(new Date(dt));
        }
        setUntrackedDays(tempUntrackedDays)
      });
    });
  }, [patient, date])

  function prepareNextSymptomTrackingStep(skipped) {
    const loggedSymptomToday = getLoggedDataToday(trackedData, "medical", date);

    const unloggedSymptoms = topSymptoms.filter((topSymptom) => {
      return !loggedSymptomToday.some(
        (loggedSymptom) => {
          return loggedSymptom.factor === topSymptom.factor;
        }
      );
    });
    const numLoggedSymptoms = topSymptoms.length - unloggedSymptoms.length

    setSkippedUnloggedSymptoms(skipped)
    setCurrentTrackingStep(numLoggedSymptoms + skipped);

    const nextSymptom = unloggedSymptoms[skipped];
    if (!nextSymptom) {
      finishTrackingStep();
    } else {
      setCurrentTrackingSymptom(nextSymptom);
    }
  }

  const finishTrackingStep = () => {
    const loggedTriggerToday = getLoggedDataToday(trackedData, "SDOH", date);
    if (loggedTriggerToday.length || currentTrackingSymptom === "SDOH") {
      setCurrentTrackingSymptom("done");
    } else {
      setCurrentTrackingSymptom("SDOH");
    }
  };

  const skipTrackingStep = () => {
    prepareNextSymptomTrackingStep(skippedUnloggedSymptoms + 1)
  };

  const saveTrackingStep = () => {
    prepareNextSymptomTrackingStep(skippedUnloggedSymptoms)
  };

  // Extracting properties from the user object
  const { userType } = user;

  const ConditionalFeelingToday =
    status != "Released" &&
    (userType == "patient" ||
      userType == "caregiver") &&
    (loadingFeelingToday ? (
      <LoadingSpinner />
    ) : (
      !doneSimpleTracking && (
        <FeelingToday
          setDoneSimpleTracking={setDoneSimpleTracking}
          date={date}
        />
      )
    ));

  const ConditionalSymptomTracker =
    status != "Released" &&
    (userType == "patient" ||
      userType == "caregiver") &&
    (loadingTopSymptoms || loadingTrackedData || loadingTopTrigger ? (
      <LoadingSpinner />
    ) : (
      <>
        {currentTrackingSymptom === "SDOH" && (
          <ComponentWrapper>
            <TriggerSelector
              topTrigger={topTrigger}
              userType={userType}
              errorLoadingTopTrigger={errorLoadingTopTrigger}
              loadTrigger={loadTrigger}
              date={date}
              skipTrackingStep={skipTrackingStep}
              saveTrackingStep={saveTrackingStep}
              loadTrackedData={loadTrackedData}
            />
          </ComponentWrapper>
        )}
        {currentTrackingSymptom !== "SDOH" &&
          currentTrackingSymptom !== "done" && (
            <ComponentWrapper>
              <SymptomTracker
                currentTrackingStep={currentTrackingStep}
                topSymptoms={topSymptoms}
                date={date}
                user={user}
                symptom={currentTrackingSymptom}
                trackedData={trackedData}
                skipTrackingStep={skipTrackingStep}
                saveTrackingStep={saveTrackingStep}
                loadTrackedData={loadTrackedData}
              />
            </ComponentWrapper>
          )}
        {currentTrackingSymptom === "done" && (
          <ComponentWrapper>
            <AdditionalNote date={date} />
          </ComponentWrapper>
        )}
      </>
    ));

  const ConditionalAddSymptoms = status != "Released" &&
    (userType == "patient" ||
      userType == "caregiver") && (
      <AddSymptoms topSymptoms={topSymptoms} setTopSymptoms={setTopSymptoms} />
    );

  const ConditionalCombinedChart =
    (loadingTrackedData || loadingTopSymptoms ? (
      <LoadingSpinner />
    ) : (
      <Charts
        topSymptoms={topSymptoms}
        data={trackedData}
        error={errorLoadingTrackedData}
        retry={loadTrackedData}
        additionalNotes={additionalNotes}
        user={user}
        patient={patient}
      />
    ));

  const ConditionalCurrentPatient = () =>
    (userType === "caregiver" ||
      userType === "provider") && (
      <Col md={3}>
        <CurrentPatient userType={userType} />
      </Col>
    );

  const ConditionalOnOffButtonMessages = () =>
    (userType === "provider") && (
      <OnOffButtonMessages
        onTitle={TOGGLE_MESSAGES[language].on}
        offTitle={TOGGLE_MESSAGES[language].off}
        caregiverList={caregiverList}
        onOffButtonMessagesStatus={onOffButtonMessagesStatus}
        setOnOffButtonMessagesStatus={setOnOffButtonMessagesStatus}
      />
    );

  const ConditionalOnOffButtonAlert = () =>
    (userType === "provider") && (
      <OnOffButtonAlert
        onTitle={TOGGLE_ALERTS[language].on}
        offTitle={TOGGLE_ALERTS[language].off}
      />
    );

  const ConditionalPatientStatus = () =>
    (userType === "provider") && (
      <PatientStatus
        status={status}
        setStatus={setStatus}
        userType={userType}
        patientId={patient?.patientId}
        patientList={patientList}
        caregiverList={caregiverList}
        setCaregiverList={setCaregiverList}
        setPatientList={setPatientList}
      />
    );

  const ConditionalCalendar = () => {
    if (userType !== "provider") {
      return <Calendar
        selectedDays={date}
        onDayClick={handleDayClick}
        modifiers={{
          tracked: trackedDays,
          partially: partiallyTrackedDays,
          untracked: untrackedDays
        }}
      />;
    }
    return null;
  };

  const ConditionalSendMessage = () =>
    <Col md={3}>
      <div className="d-flex justify-content-center">
        <h6
          className="pt-2 pb-2 pl-3 pr-3"
          style={{
            width: "75%",
            height: "100%",
            borderRadius: "20px",
            backgroundColor: dark
              ? theme.darkModePrimary
              : unreadMessagesPresent
                ? "#3EB1A6"
                : "#ef5866",
            color: dark ? theme.darkModeText : "white",
            textAlign: "center",
            overflowWrap: "break-word",
            cursor: "pointer",
          }}
          onClick={() =>
            history.push("/messages", {
              curPatient: patient,
            })
          }
        >
          {unreadMessagesPresent ? (
            <div>
              {MAILBOX[language].new}
              <img
                style={{
                  maxHeight: "30px",
                  maxWidth: "30px",
                  display: "inline-block",
                  paddingLeft: 10,
                }}
                src={emailAlert}
                alt="email alert"
              />
            </div>
          ) : (
            MAILBOX[language].send_or_read
          )}
        </h6>
      </div>
    </Col>
    ;

  const ConditionalAssignmentButton = () => {
    if (userType === "provider") {
      return (
        <div className="mb-4 mt-3">
          <AssignmentButton
            userId={user.userId}
            patientId={patient?.patientId}
          />
        </div>
      );
    }
  };

  const ConditionalHemisphericSum = () => {
    if (userType === "provider") {
      return (
        <div>
          <HemisphericSum
            patientId={patient?.patientId}
          />
        </div>
      );
    }
  };

  const ConditionalTherapyTracker = () => {
    if (userType !== "provider") {
      return (
        <div className='mt-3'>
          <TherapyTracker
            patientId={patient?.patientId}
            date={date}
          />
        </div>
      );
    }
  };

  const VerifyEmailMessage =
    user.isVerified === false && user.userType !== "provider" && showPopUp ? (
      <Alert
        variant="warning"
        onClose={() => {
          setShowPopUp(false);
        }}
        dismissible
      >
        <Alert.Heading>{VERIFY_EMAIL[language].header}</Alert.Heading>
        <p>{VERIFY_EMAIL[language].body[0]}</p>
        <p>
          {`${VERIFY_EMAIL[language].body[1]} ${user.email}${VERIFY_EMAIL[language].body[2]}`}
        </p>
      </Alert>
    ) : null;

  const getPossibleMessages = () => ({
    sallieText: {
      Stroke: SALLIE_DASHBOARD[language].patient,
      tbiPatient: SALLIE_DASHBOARD[language].patient,
      caregiver: SALLIE_DASHBOARD[language].non_patient,
      provider: SALLIE_DASHBOARD[language].non_patient,
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  if (width >= 768) {
    return (
      <>
        {VerifyEmailMessage}
        <Row>
          <Col md={9}>
            {user.userType === "provider" && (
              <Sallie
                user={user}
                text={getMessage("sallieText")}
                isProvider={true}
              />
            )}
            {(user.userType === "caregiver") && (
              <Sallie text={getMessage("sallieText")} isCG={true} />
            )}
            {user.userType === "patient" && (
              <Sallie text={getMessage("sallieText")} />
            )}
          </Col>
          <Col>
            <ThemeChanger />
          </Col>
        </Row>
        {/* End top row */}
        <Row className="d-flex align-items-start justify-content-between ">
          {ConditionalCurrentPatient()}
          {ConditionalSendMessage()}
        </Row>

        <Row>
          {/* One shorter left col and one larger right col */}
          <Col md={4}>
            {ConditionalTherapyTracker()}
            {user.userType !== "provider" && <EditNotification />}
            <ConditionalCalendar />
            {ConditionalAssignmentButton()}
            {ConditionalOnOffButtonMessages()}
            {ConditionalOnOffButtonAlert()}
            {ConditionalPatientStatus()}
            {ConditionalHemisphericSum()}
          </Col>
          <Col md={8}>
            {ConditionalFeelingToday}
            {ConditionalSymptomTracker}
            {ConditionalAddSymptoms}
            {ConditionalCombinedChart}
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        {VerifyEmailMessage}
        <Row>
          <Col md={8}>
            {userType === "provider" && (
              <Sallie text={getMessage("sallieText")} isProvider={true} />
            )}
            {(userType === "caregiver") && (
              <Sallie text={getMessage("sallieText")} isCG={true} />
            )}
            {(userType === "patient") && (
              <Sallie text={getMessage("sallieText")} />
            )}
          </Col>
          <Col>
            <ThemeChanger />
          </Col>
        </Row>
        {/* End top row */}
        <Row className="d-flex align-items-center justify-content-center">
          {ConditionalCurrentPatient()}
          {ConditionalSendMessage()}
        </Row>
        <Row>
          {/* One shorter left col and one larger right col */}
          <Col md={4}>
            {user.userType !== "provider" && <EditNotification />}
            <ConditionalCalendar />
            {ConditionalOnOffButtonMessages()}
            {ConditionalOnOffButtonAlert()}
            {ConditionalPatientStatus()}
          </Col>

          <Col md={8}>
            {ConditionalFeelingToday}
            {ConditionalSymptomTracker}
            {ConditionalAddSymptoms}
            {ConditionalCombinedChart}
          </Col>
        </Row>
      </>
    );
  }
};

export default Dashboard;
