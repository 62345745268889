import React from "react";
import { Modal } from "react-bootstrap";

import "./TherapyTracker.scss";

const TherapyTracker = ({
    therapyList,
    pausedTherapyList,
    showModal,
    setShowModal,
    newTherapyName,
    setNewTherapyName,
    handleAddTherapy,
    handlePauseTherapy,
    handleUnpauseTherapy,
    handleDeleteTherapy,
    handleRecordTherapy
}) => {
    return (
        <div className="therapy-tracker-container">
            <h6 className='therapy-tracker-header'>Therapy Tracker</h6>
            <p><b>Please track your therapies for the day.</b></p>
            {therapyList.length > 0 ? (
                <ol className="p-0">
                    {therapyList.map((therapy) => (
                        <li key={therapy.therapy_id}>
                            <div className="therapy-details">
                                <span>{therapy.therapy_name}</span>
                                <div className="therapy-actions">
                                    <button
                                        type="button"
                                        disabled={therapy.session_count > 0}
                                        className={
                                            Number(therapy.session_count) === 1
                                                ? "btn btn-primary btn-sm"
                                                : "btn btn-outline-primary btn-sm"
                                        }
                                        onClick={() => handleRecordTherapy(therapy.therapy_id, therapy.therapy_name, 1)}
                                    >
                                        Once
                                    </button>
                                    <button
                                        type="button"
                                        disabled={therapy.session_count > 0}
                                        className={
                                            Number(therapy.session_count) === 2
                                                ? "btn btn-primary btn-sm"
                                                : "btn btn-outline-primary btn-sm"
                                        }
                                        onClick={() => handleRecordTherapy(therapy.therapy_id, therapy.therapy_name, 2)}
                                    >
                                        Twice
                                    </button>
                                    <button
                                        type="button"
                                        disabled={therapy.session_count > 0}
                                        className={
                                            Number(therapy.session_count) === 3
                                                ? "btn btn-primary btn-sm"
                                                : "btn btn-outline-primary btn-sm"
                                        }
                                        onClick={() => handleRecordTherapy(therapy.therapy_id, therapy.therapy_name, 3)}
                                    >
                                        Thrice
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-warning btn-sm"
                                        onClick={() => handlePauseTherapy(therapy.therapy_id)}
                                    >
                                        Pause
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDeleteTherapy(therapy.therapy_id)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ol>
            ) : (
                <p>No active therapies.</p>
            )}
            <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>Add New Therapy</button>
            {pausedTherapyList.length > 0 ? (
                <>
                    <hr />
                    <h6>Paused Therapies</h6>
                    <ol className="p-0">
                        {pausedTherapyList.map((therapy) => (
                            <li key={therapy.therapy_id}>
                                <div className="therapy-details">
                                    <span>{therapy.therapy_name}</span>
                                    <div className="therapy-actions">
                                        <button type="button" className="btn btn-warning btn-sm" onClick={() => handleUnpauseTherapy(therapy.therapy_id)}>Unpause</button>
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteTherapy(therapy.therapy_id)}>Delete</button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </>
            ) : null}
            <Modal className="therapy-modal d-flex align-items-center" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Therapy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        className='therapy-modal-input'
                        type="text"
                        placeholder="Enter therapy name"
                        value={newTherapyName}
                        onChange={(e) => setNewTherapyName(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer className='therapy-modal-footer'>
                    <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={handleAddTherapy} disabled={!newTherapyName.trim()}>Add Therapy</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TherapyTracker;