/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { useHistory, useLocation } from "react-router-dom";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Form } from "react-bootstrap";

import MelilloBrain1 from '../../images/melillo-brain1.jpg';
import MelilloBrain2 from '../../images/melillo-brain2.jpg';

import Sallie from "../Sallie";
import MedicalListSelector from "../MedicalListSelector";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";

import { getTBISymptoms, postRegisterFactors } from "../../api/TBIRequests";

import useListSelector from "../../lib/useListSelector";
import customizeMessages from "../../lib/customizeMessages";

import {
  MEDICAL_SYMPTOMS,
  SALLIE_BRAIN,
  SYMPTOM_SELECTOR,
  SAVE_AND_CONTINUE,
} from "../../constants/OnboardingTranslation";

const SelectLaterSymptoms = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);
  const [loading, setLoading] = useState(true);
  const [returnedData, setReturnedData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [dateOfTBI, setDateOfTBI] = useState(undefined);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [headacheParts, setHeadacheParts] = useState([]);
  const availableSymptomsContainerRef = useRef(null);
  const selectedSymptomsContainerRef = useRef(null);

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const history = useHistory();
  const location = useLocation();
  const { language } = useContext(PreferredLanguageContext);

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  // Maps to Step 4 in the DB, Steps 2 and 3 have been removed from the workflow
  const getPossibleMessages = ({ getPronoun, isOtherGender }) => ({
    mainHeader: {
      tbiPatient: MEDICAL_SYMPTOMS[language],
      caregiver: MEDICAL_SYMPTOMS[language],
    },
    sallieText: {
      tbiPatient: SALLIE_BRAIN[language].tbiPatient,
      caregiver: SALLIE_BRAIN[language].caregiver,
    },
    duplicatesText: {
      tbiPatient: SYMPTOM_SELECTOR[language].errors.duplicate,
      caregiver: SYMPTOM_SELECTOR[language].errors.duplicate,
    },
    noSymptomsText: {
      tbiPatient: SYMPTOM_SELECTOR[language].errors.patient,
      caregiver: SYMPTOM_SELECTOR[language].errors.caregiver,
    },
    selectHeadacheLocationText: {
      tbiPatient:
        SYMPTOM_SELECTOR[language].errors.headache_location,
      caregiver:
        SYMPTOM_SELECTOR[language].errors.headache_location,
    },
  });
  const getMessage = customizeMessages({ user, getPossibleMessages });

  const changeErrorMsg = (errorCase) => {
    const errorMsgList = {
      hasDuplicates: getMessage("duplicatesText"),
      noSymptom: getMessage("noSymptomsText"),
      needHeadAcheLocation: getMessage("selectHeadacheLocationText"),
    };
    setErrorMsg(errorMsgList[errorCase]);
    setShowErrorMsg(true);
  };

  const categories = [
    "motor",
    "sensory",
    "emotional",
    "behavioral",
    "cognitive",
    "immune",
    "metabolic"
  ];
  const currentCategory = categories[currentCategoryIndex];

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        factor: item,
        category: "medical",
        subcategory: category,
        datetimeOfSymptom: new Date(),
        hadSymptom: true,
      }));
    });
  };

  const createItemListFromRes = (res) => {
    return categories.map((category) => {
      const items = res.data
        .filter((item) => item.subcategory === category)
        .map((item) => item.factor);
      return { category, items };
    });
  };

  useEffect(() => {
    location.state?.fromPatientControl
      ? setOnboardingPercent(40)
      : setOnboardingPercent(40);
    getTBISymptoms()
      .then((res) => {
        const itemList = createItemListFromRes(res);

        setReturnedData(itemList);
        initialize(itemList);
        setLoading(false);
      })
      .catch(() => {
        history.push("/oops");
      });
    // if we include initialize in the deps we get an infinite loop
    // TODO: Extract logic of init into outer function to use dep arr correctly
  }, []);

  // scroll to top of container when navigating between categories
  useEffect(() => {
    if (availableSymptomsContainerRef.current) {
      availableSymptomsContainerRef.current.scrollTop = 0;
    }
  }, [currentCategoryIndex])

  // keeps list of selected symptoms scrolled to bottom when new symptoms are added
  useEffect(() => {
    if (selectedSymptomsContainerRef.current) {
      selectedSymptomsContainerRef.current.scrollTop = selectedSymptomsContainerRef.current.scrollHeight;
    }
  }, [selectedData])

  const handlePrev = () => {
    if (currentCategoryIndex > 0) {
      setCurrentCategoryIndex((prev) => prev - 1);
    }
  };

  const handleNextOrSubmit = () => {
    if (currentCategoryIndex < categories.length - 1) {
      setCurrentCategoryIndex((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    let processedData = processDataForSending();

    if (processedData.length === 0) {
      changeErrorMsg("noSymptom");
      return;
    }

    processedData = processedData.filter((item) => item.factor !== "None");

    setSubmitting(true);

    try {
      postRegisterFactors({
        patientId: patient.patientId,
        data: processedData,
      }).then(() => {
        {
          //add more symptoms,if there's no tracking factors
          location?.state?.fromPatientDashboard ?
            history.push("/selectTopSymptoms", {
              forwardData: { selectedData, processedData, dateOfTBI },
              fromPatientControl: location.state?.fromPatientControl,
              fromPatientDashboard: true,
            }) :
            history.push("/selectTopSymptoms", {
              forwardData: { selectedData, processedData, dateOfTBI },
              fromPatientControl: location.state?.fromPatientControl,
            })
        }
        ;
      });
    } catch (err) {
      console.log("some error in SelectLaterSymptoms handleSubmit");
      console.log(err);
      history.push("/oops");
    }
  };

  const tabs = {
    motor: 'Motor Symptoms',
    sensory: 'Sensory Symptoms',
    emotional: 'Emotional Symptoms',
    behavioral: 'Behavioral Symptoms',
    cognitive: 'Cognitive Symptoms',
    immune: 'Immune Symptoms',
    metabolic: 'Metabolic Symptoms',
  };

  const colors = {
    motor: "#b3e5fc",
    sensory: "#ffccbc",
    emotional: "#dab7ec",
    behavioral: "#ffdadb",
    cognitive: "#ffdcaf",
    immune: "#fff3c4",
    metabolic: "#cef3c4",
  };

  const colorsSelected = {
    motor: "#b3e5fc",
    sensory: "#ffccbc",
    emotional: "#dab7ec",
    behavioral: "#ffdadb",
    cognitive: "#ffdcaf",
    immune: "#fff3c4",
    metabolic: "#cef3c4",
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <Row className="justify-content-center">
            <Col xs={10} md={8}>
              <OnboardingStepHeader text={getMessage("mainHeader")} />
              <Sallie text={getMessage("sallieText")} style={{ textAlign: "left" }} />
              <Row>
                <Col sm={6}>
                  <img src={MelilloBrain1} style={{ display: 'inlineBlock', height: "18rem", width: "100%", marginBottom: "2rem", marginTop: "2rem" }}></img>
                </Col>
                <Col sm={6}>
                  <img src={MelilloBrain2} style={{ display: 'inlineBlock', height: "18rem", width: "100%", marginBottom: "2rem", marginTop: "2rem" }}></img>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-between" style={{ marginTop: "1rem" }}>
            <Col sm={12}>
              <h5 style={{ textAlign: "center" }}>{SYMPTOM_SELECTOR[language].category_header}</h5>
            </Col>
          </Row>
          <Tab.Container id="left-tabs-example" activeKey={currentCategory}>
            <Row style={{ marginTop: "0.5rem" }}>
              <Col sm={3} >
                <Nav fill justify variant="tabs" className="flex-column">
                  {returnedData.map((itemList, i) => (
                    <Nav.Item key={itemList.category}>
                      <Nav.Link
                        eventKey={itemList.category}
                        disabled
                        style={{
                          background: `${colors[itemList.category]}`,
                          border: '1px solid #106995',
                          fontWeight:
                            itemList.category === currentCategory
                              ? "bold"
                              : "normal",
                        }}>
                        {tabs[itemList.category]}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={5}>
                <Tab.Content ref={availableSymptomsContainerRef}>
                  {returnedData.map((itemList, i) => (
                    <Tab.Pane key={itemList.category} eventKey={itemList.category}
                    >
                      <MedicalListSelector
                        key={itemList.category}
                        patient={patient}
                        category={itemList.category}
                        message={tabs[itemList.category]}
                        list={itemList.items}
                        index={i}
                        selectedList={selectedData[itemList.category]}
                        toggleFromSelectedList={toggleFromSelectedList}
                        selectOne={switchSingleSelection}
                        setHeadacheParts={setHeadacheParts}
                        headacheParts={headacheParts}
                        symptom_selector
                        noOther
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
              <Col sm={4}>
                <div
                  ref={selectedSymptomsContainerRef}
                  className="justify-content-left"
                  style={{
                    height: "30rem",
                    overflow: 'scroll',
                    color: 'black',
                    border: '1px solid #106995',
                    textAlign: "left",
                    background: "#fff",
                    padding: "2rem",
                    borderRadius: "5px",
                    margin: ".25rem 0",
                  }}>
                  {Object.entries(selectedData).flatMap(([category, itemList]) => {
                    return itemList.map((item) => (
                      <div style={{ margin: '0.5rem 0' }}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>icon/action/check_ccircle_24px</title>
                          <defs>
                            <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="path-1"></path>
                          </defs>
                          <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <mask id="mask-2" fill="white">
                              <use xlinkHref="#path-1"></use>
                            </mask>
                            <use id="-↳Color" fill={colorsSelected[category]} xlinkHref="#path-1"></use>
                          </g>
                        </svg>
                        {" "}
                        {item}
                      </div>));
                  })}
                </div>
              </Col>
            </Row>
          </Tab.Container>
          <Row>
            <Col>
              {showErrorMsg && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  {errorMsg}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-between align-items-center" style={{ marginTop: "1rem" }}>
            <Col xs="auto">
              <SubmitButton
                type="button"
                onClick={handlePrev}
                disabled={currentCategoryIndex === 0}
                greyout={currentCategoryIndex === 0}
              >
                &lt; Previous Category
              </SubmitButton>
            </Col>
            <Col xs="auto">
              <SubmitButton
                type="button"
                onClick={handleNextOrSubmit}
                disabled={submitting}
              >
                {currentCategoryIndex < categories.length - 1
                  ? "Next Category >"
                  : `${SAVE_AND_CONTINUE[language]} >`}
              </SubmitButton>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SelectLaterSymptoms;
