import React, { useState, useEffect } from "react";

import { getTotalHemisphereDeficit } from "../../../api/TBIRequests";

import HemisphericSum from "./HemisphericSum";

const HemisphericSumContainer = ({ patientId }) => {
    const [totalLeftHemisphereDeficit, setTotalLeftHemisphereDeficit] = useState(0);
    const [totalRightHemisphereDeficit, setTotalRightHemisphereDeficit] = useState(0);

    useEffect(() => {
        const fetchTotalHemisphereDeficit = async () => {
            try {
                const res = await getTotalHemisphereDeficit({ patientId });
                console.log('res', res.data)
                setTotalLeftHemisphereDeficit(res.data.total_left_hemisphere_deficit);
                setTotalRightHemisphereDeficit(res.data.total_right_hemisphere_deficit);
            } catch (err) {
                console.error('Error fetching total hemisphere deficit', err)
            }
        }
        fetchTotalHemisphereDeficit();
    }, []);

    return (
        <HemisphericSum
            totalLeftHemisphereDeficit={totalLeftHemisphereDeficit}
            totalRightHemisphereDeficit={totalRightHemisphereDeficit}
        />
    )
}
export default HemisphericSumContainer;