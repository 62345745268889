import { jsPDF } from "jspdf";
import "jspdf-autotable";

import MelilloLogo from "../images/MelilloLogo.png";
import PofPLogo from "../images/Logo.png";

// utility function
const capitalize = (text) => {
    if (!text) return "N/A";
    return text
        .toString()
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
};
// end utility function

const downloadFollowUpForm = (answers, name, date) => {
    const pdf = new jsPDF();
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;

    const marginBottom = 20;
    const xMargin = 20;
    const contentWidth = pageWidth - xMargin * 2; // Usable width between margins
    let yOffset = 20;

    const addHeader = () => {
        const logoHeight = 15; // Fixed height for the logo
        const logoWidth = 40; // Approximate width of the logo
        const leftLogoY = yOffset; // Top Y-coordinate for the logo
        const titleX = xMargin + logoWidth + 5; // Adjust title X-position to avoid logo overlap
        const titleY = leftLogoY + logoHeight / 2 + 2; // Center the title with the logo

        // First Logo (Left-Aligned)
        pdf.addImage(MelilloLogo, "PNG", xMargin, leftLogoY, logoWidth, logoHeight);

        // Title
        const title = `At Home Follow Up Form for ${name} (${new Date(date).toLocaleDateString()})`;
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(14);
        pdf.text(title, titleX, titleY);

        yOffset += logoHeight + 10; // Update yOffset for subsequent content
    };

    addHeader();

    // Helper Functions //
    // check if a new page needs to be added
    const checkPageHeight = () => {
        if (yOffset + 10 > pageHeight - marginBottom) {
            pdf.addPage(); // Add a new page
            yOffset = 20; // Reset yOffset for the new page
        }
    };

    // adding sections for questions
    const addSection = (title, value) => {
        checkPageHeight();

        pdf.setFont("helvetica", "italic");
        pdf.setFontSize(12);

        const titleWidth = pdf.getTextWidth(title);
        const answerX = Math.min(xMargin + titleWidth + 5, contentWidth); // Ensure answers fit within content width

        pdf.text(title, xMargin, yOffset);

        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(10);
        pdf.text(capitalize(String(value) || "N/A"), answerX, yOffset);

        yOffset += 12.5; // Add spacing after each line
    };

    // end helper functions //

    // Body
    addSection("Was there any change in medication?", answers.changeMedication);
    addSection("Is there a reduction or elimination in medication from the beginning?", answers.lessMedication);
    if (answers.lessMedication === "yes") {
        addSection("Medication Reduction/Elimination Details:", answers.medicineChangeDetails);
    }

    addSection("What percentage of the overall program are you doing?", `${answers.overallProgramPercentage}%`);

    addSection("Have you been doing the Primitive Reflex Exercises?", answers.primitiveReflex);
    if (answers.primitiveReflex === "yes") {
        addSection("Primitive Reflex Daily Frequency:", `${answers.primitiveReflexDaily} times per day`);
        addSection("Primitive Reflex Weekly Frequency:", `${answers.primitiveReflexWeekly} days per week`);
    }

    addSection("Have you been doing Hemispheric Sensory Stimulation?", answers.hemisphericSensory);
    if (answers.hemisphericSensory === "yes") {
        addSection("Hemispheric Sensory Specifications:", answers.hemisphericSensorySpecification.join(", "));
        addSection("Hemispheric Sensory Daily Frequency:", `${answers.hemisphericSensoryDaily} times per day`);
        addSection("Hemispheric Sensory Weekly Frequency:", `${answers.hemisphericSensoryWeekly} days per week`);
    }

    addSection("Have you been on a special diet?", answers.specialDiet);
    if (answers.specialDiet === "yes") {
        addSection("Special Diet Details:", answers.describeSpecialDiet);
    }

    addSection("Have you been taking vitamins?", answers.vitamins);

    addSection("Have you seen any regression or loss of function?", answers.functionLoss);
    if (answers.functionLoss === "yes") {
        addSection("Function Regression/Loss Details:", answers.describeFunctionLoss);
    }

    addSection("Have you seen any improvements in or beginning to have new skills?", answers.skills);
    if (answers.skills === "yes") {
        addSection("Skills Details:", answers.describeSkills);
    }

    // Summary table for percentage improvements
    const summaryTable = [
        {
            category: "Nonverbal Communication",
            improvement: `${answers.nonVerbalCommunication}%`,
        },
        {
            category: "Verbal Communication",
            improvement: `${answers.verbalCommunication}%`,
        },
        {
            category: "Academic Skills",
            improvement: `${answers.academicSkills}%`,
        },
        {
            category: "Behavior",
            improvement: `${answers.behavior}%`,
        },
        {
            category: "Physical Changes",
            improvement: `${answers.physicalChange}%`,
        },
        {
            category: "Overall Progress",
            improvement: `${answers.overallProgress}%`,
        },
    ];

    // Check available space before rendering the table
    const calculateTableHeight = (rowCount, lineHeight) => rowCount * lineHeight;

    const tableRowHeight = 15; // Approximate height for each row
    const tableHeight = calculateTableHeight(summaryTable.length + 1, tableRowHeight);

    if (yOffset + tableHeight > pdf.internal.pageSize.height - 20) {
        pdf.addPage();
        yOffset = 20;
    }

    pdf.setFont("helvetica", "italic");
    pdf.setFontSize(12);
    pdf.text("Percentage Improvements Summary", xMargin, yOffset);
    yOffset += 5;

    pdf.autoTable({
        startY: yOffset,
        margin: { left: xMargin, right: xMargin }, // Set left and right margins for the table
        head: [["Category", "Improvement"]],
        body: summaryTable.map((row) => [row.category, row.improvement]),
        theme: "striped",
    });

    yOffset = pdf.lastAutoTable.finalY + 10;

    // Add additional comments
    addSection("Additional Comments:", answers.additionalComments || "None");
    addSection("Do you have a follow up appointment scheduled?", answers.followUpAppointment);

    // Adding Factors Table
    const addFactorsTable = (factors) => {
        checkPageHeight(); // Ensure there's space before the table

        pdf.setFont("helvetica", "italic");
        pdf.setFontSize(12);
        pdf.text("Recently Selected Daily Activities", xMargin, yOffset);
        yOffset += 5;

        // Prepare table data
        const tableData = factors.map((factor) => [factor.category, factor.factor]);

        pdf.autoTable({
            startY: yOffset,
            margin: { left: xMargin, right: xMargin },
            head: [["Category", "Factor"]], // Table headers
            body: tableData, // Table rows
            theme: "striped",
        });

        yOffset = pdf.lastAutoTable.finalY; // Update yOffset after table
    };

    addFactorsTable(answers.factors);

    // Add Footer with Second Logo
    const addFooter = (pageNumber, totalPages) => {
        const logoHeight = 15; // Fixed height for the logo
        const footerY = pageHeight - marginBottom - logoHeight; // Position above the page margin

        // Footer Logo (Left-Aligned)
        pdf.addImage(PofPLogo, "PNG", xMargin, footerY, 0, logoHeight); // Left-aligned logo

        // Footer Text (Right-Aligned)
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        const pageNumberText = `Page ${pageNumber} of ${totalPages}`;
        const pageNumberWidth = pdf.getTextWidth(pageNumberText);
        pdf.text(
            pageNumberText,
            pageWidth - xMargin - pageNumberWidth, // Right-aligned
            pageHeight - marginBottom - 5
        );
    };

    // Add Footer with page numbers
    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        addFooter(i, totalPages); // Add footer for each page
    }


    // Save the PDF
    pdf.save("Follow_Up_Form.pdf");
};

export default downloadFollowUpForm;
