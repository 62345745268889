import * as yup from "yup";
import postalCodes from "postal-codes-js";

let today = new Date();

const SignupSchema = ({ isProviderForm }) => {
    return yup
        .object()
        .shape({
            userType: yup.string().nullable().required("Registration type is required"),
            providerType: yup.string().when("userType", {
                is: "provider",
                then: yup.string().required("Provider type is required"),
                otherwise: yup.string().notRequired(),
            }),
            firstName: yup.string().trim().required("First name required"),
            lastName: yup.string().trim().required("Last name required"),
            email: yup.string().trim().email("Enter valid email").required("Email required"),
            dob: yup
                .date()
                .typeError("Enter valid date")
                .min(new Date(1900, 1, 1), "Enter valid date")
                .max(
                    new Date(today.getFullYear() - 13, today.getMonth(), today.getDate()),
                    "You must be 13 or older"
                )
                .required(),
            visitType: yup.string().when("userType", {
                is: (userType) => userType !== "provider",
                then: yup.string().required("Visit type is required"),
                otherwise: yup.string().notRequired(),
            }),
            password: yup
                .string()
                .required("Password is required")
                .min(8, "Password must be at least 8 characters"),
            // .matches(
            //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!% *#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
            // )
            passwordConfirmation: yup
                .string()
                .required("Password Confirmation is required")
                .oneOf([yup.ref("password"), null], "Passwords must match"),

            clinicName: yup.string().required("Clinic name is required"),
            clinicLocation: yup.string().required("Clinic location is required"),
            country: yup.string().trim().required("Country is required"),
            postalCode: yup.string().trim().required("Postal code is required"),
        })
        .test("zipcodeValidation", null, (obj) => {
            let validationResult = postalCodes.validate(obj.country, obj.postalCode);
            if (validationResult === true) {
                return true;
            } else {
                return new yup.ValidationError(validationResult, null, "postalCode");
            }
        });
}

export default SignupSchema;
