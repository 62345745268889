import React from "react";
import "./HemisphericSum.scss";

const HemisphericSum = ({ totalLeftHemisphereDeficit, totalRightHemisphereDeficit }) => {
    return (
        <div className="hemispheric-sum-container">
            <p className='hemispheric-sum-header'>Total Hemisphere Deficit</p>
            <div className='hemispheric-sum-row'>
                <div>
                    <div>Left</div>
                    <p>
                        {totalLeftHemisphereDeficit ?? "N/A"}
                    </p>
                </div>
                <div>
                    <div>Right</div>
                    <p>
                        {totalRightHemisphereDeficit ?? "N/A"}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HemisphericSum;