import React from "react";
import DayPicker from "react-day-picker";
import ComponentWrapper from "./ComponentWrapper";

const Calendar = ({ selectedDays, onDayClick, modifiers }) => {
  const today = new Date()
  return (
    <ComponentWrapper>
      <DayPicker
        modifiers={modifiers}
        onDayClick={onDayClick}
        selectedDays={selectedDays}
        disabledDays={{ after: today }}
      />
    </ComponentWrapper>
  );
};

export default Calendar;
