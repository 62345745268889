import React, { useState, useEffect, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import {
  PATIENT_INFO,
  NAME,
  GENDER,
  DOB,
  CHOOSE,
  CONFIRM
} from "../../constants/OnboardingTranslation";

// Hooks
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import useListSelector from "../../lib/useListSelector";
import StrokeListSelecter from "../StrokeListSelecter";
import { yupResolver } from "@hookform/resolvers";

// Bootstrap
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

// Validation Rules
import PatientSchema from "./validations/registerPatientValidation";

// api
import { getCaregiverListForProvider, registerPatient } from "../../api/auth";
import { getDiagnoses, getPatientTypes, postDiagnoses } from "../../api/TBIRequests";

// Components
import Sallie from "../Sallie";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";
import DiagnosisSelector from "../DiagnosisSelector";

const PatientInfo = ({
  setOnboardingPercent,
  setPatientList,
  user,
  setPatient,
}) => {
  const { language } = useContext(PreferredLanguageContext);

  const [returnedData, setReturnedData] = useState([]);
  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    location.state?.fromPatientControl
      ? setOnboardingPercent(20)
      : setOnboardingPercent(15);
  }, [language]);

  const [isLoading, setIsLoading] = useState(false);
  const [otherRelationshipType, setOtherRelationshipType] = useState(false);
  const [otherStatus, setOtherStatus] = useState(false);
  const [patientTypes, setPatientTypes] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(PatientSchema),
  });

  const [diagnoses, setDiagnoses] = useState([]);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    getDiagnoses()
      .then((res) => {
        setDiagnoses(res.data)
      })
      .catch((err) => {
        console.error(err);
        history.push("/oops");
      });

    getPatientTypes()
      .then((res) => {
        if (isMounted) {
          const { data } = res;
          setPatientTypes(data);
        }
      })
      .catch((error) => {
        // Handle any errors
        setError(true);
      });
    // Cleanup function
    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, []);

  const handleSelectDiagnosis = (id) => {
    if (selectedDiagnoses.includes(id)) {
      setSelectedDiagnoses(selectedDiagnoses.filter(d => d != id))
    } else {
      setSelectedDiagnoses([...selectedDiagnoses, id])
    }
  }

  const onChange = (e) => {
    if (e.target.name === "status") {
      setOtherStatus(e.target.value === "Other");
    } else if (e.target.name === "relationshipType") {
      setOtherRelationshipType(e.target.value === "other");
    }
  };

  const onSubmit = async (data) => {
    data["patientType"] = 'Other';
    data["patientSubType"] = 'Autism';
    data["relationshipType"] = 'Child';

    try {
      setIsLoading(true);
      const responsePatientData = await registerPatient(data);
      postDiagnoses({ data: selectedDiagnoses, patientId: responsePatientData.data.patientId });

      setPatient(responsePatientData.data);
      const getCaregiverListForProviderResponse = await getCaregiverListForProvider();
      setPatientList(getCaregiverListForProviderResponse.data);

      history.push("/laterSymptoms", {
        fromPatientControl: location.state?.fromPatientControl,
      });
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      history.push("/oops");
    }
  };

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/signIn",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <>
      <OnboardingStepHeader text={PATIENT_INFO[language].header} />
      <Sallie text={PATIENT_INFO[language].sallie} />
      <Form
        autoComplete="on"
        action="/post"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Row className='mt-3'>
          <Col md={6}>
            <LabelledInput
              name="firstName"
              required
              label={NAME[language].first}
              autoComplete="given-name"
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <LabelledInput
              name="lastName"
              autoComplete="family-name"
              label={NAME[language].last}
              required
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <Form.Group controlId="gender">
              <Form.Label>
                {GENDER[language].label}<span className="required">*</span>
              </Form.Label>
              <Form.Control
                name="gender"
                as="select"
                ref={register}
                isInvalid={errors.gender}
              >
                <option value=""></option>
                <option value="male">{GENDER[language].male}</option>
                <option value="female">{GENDER[language].female}</option>
                <option value="other">{GENDER[language].other}</option>
              </Form.Control>
              {language !== 'spanish' &&
                <Form.Control.Feedback type="invalid">
                  {errors.gender && errors.gender.message}
                </Form.Control.Feedback>
              }
            </Form.Group>
          </Col>

          <Col md={6}>
            <LabelledInput
              name="dob"
              type="DOB"
              label={DOB[language]}
              required
              inputRef={register}
              errors={errors}
            />
          </Col>

        </Form.Row>

        <>
          <p>
            Patient Diagnoses
            <span className="required">*</span>
          </p>
          <Col md={12} className="mt-3">
            <DiagnosisSelector
              items={diagnoses}
              selectedItems={selectedDiagnoses}
              selectItem={handleSelectDiagnosis}
            />
          </Col>
        </>

        <Col md={12}>
          <SubmitButton disabled={isLoading} type="submit">
            {CONFIRM[language]}
          </SubmitButton>
        </Col>
        {errors.status && (
          <Form.Control.Feedback className="d-block" type="invalid">
            {errors.status && errors.status.message}
          </Form.Control.Feedback>
        )}
      </Form>
    </>
  );
};

export default PatientInfo;
