import React, { useState, useEffect, useContext } from "react";
import "./basicinfo.scss";

// Hooks
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

// Bootstrap
import Form from "react-bootstrap/Form";
import { Col, Row, Container } from "react-bootstrap";

// Validation Rules
import SignupSchema from "./validations/registerValidation";
import {
  registerUser,
  registerPatient,
  connectInvitedPatientWithP,
} from "../../api/auth";
import {
  getProviderTokenInformation,
  getProviderID,
  sendConfirmEmail,
  checkExistingList,
} from "../../api/helpReq";
import { getDiagnoses, getProviderTypes, postDiagnoses, } from "../../api/TBIRequests";

// Components
import Sallie from "../Sallie";
import PrivacyPagePatient from "../PrivacyPagePatient";
import LinkButton from "../StyledComponents/LinkButton";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";
import PolicyComponent from "./PolicyComponent";

// lib
import useListSelector from "../../lib/useListSelector";

// API
import CountryList from "../../api/countryList.json";
import ClinicList from "../../api/clinicList.json";
import { verifyLicenseKey } from '../../api/paymentRequest';

// Translation
import { PreferredLanguageContext } from '../../lib/main-context'
import {
  STEP_1A,
  BASIC_INFORMATION,
  UNDER_13,
  REGISTRATION_TYPE,
  PROVIDER_TYPE,
  NAME,
  EMAIL,
  DOB,
  COUNTRY,
  CLINIC,
  VISIT_TYPE,
  ZIPCODE,
  PASSWORD,
  MESSAGES_AND_ALERTS,
  TERMS_AND_CONDITIONS,
  SAVE_AND_CONTINUE,
} from '../../constants/OnboardingTranslation'
import DiagnosisSelector from "../DiagnosisSelector";

const BasicInfo = ({
  setOnboardingPercent,
  setUser,
  setPatient,
  setPatientList,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [viewPolicyPatient, setViewPolicyPatient] = useState(false);
  const [alertPolicy, setAlertPolicy] = useState(true);
  const [messagePolicy, setMessagePolicy] = useState(true);
  const [providerEmail, setProviderEmail] = useState(null);
  const [providerTypes, setProviderTypes] = useState(null);
  const [providerId, setProviderId] = useState(null);
  const [diagnoses, setDiagnoses] = useState([]);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [registrationType, setRegistrationType] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [readAlert, setReadAlert] = useState(false);
  const [selectedClinicName, setSelectedClinicName] = useState("Melillo");
  const [clinicLocations, setClinicLocations] = useState(["Melillo NYC", "Melillo RVC"]);

  const url = new URLSearchParams(location.search);
  const token = url.get("token");
  const isProviderForm =
    token &&
    !token.startsWith("invitedPatient");
  const isInvitedPatientByProvider =
    token && token.startsWith("invitedPatientP");
  const { register, handleSubmit, errors, setError, setValue } = useForm({
    resolver: yupResolver(SignupSchema({ isProviderForm })),
  });

  const { language } = useContext(PreferredLanguageContext);

  useEffect(() => {
    setOnboardingPercent(10);

    getDiagnoses()
      .then((res) => {
        setDiagnoses(res.data)
      })
      .catch((err) => {
        console.error(err);
        history.push("/oops");
      });

    if (location.search.length > 0) {
      setValue("email", url.get("email"));
      setValue("firstName", url.get("first"));
      setValue("lastName", url.get("last"));
      if (isProviderForm) {
        const data = { token };
        getProviderTokenInformation({ data })
          .then((res) => {
            const { data } = res;
            // setValue("email", data.providerEmail);
            // Assuming we are hiding the email field for providers in the JSX
            setProviderEmail(data.providerEmail);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      } else if (isInvitedPatientByProvider) {
        const data = { token };
        getProviderID({ data })
          .then((res) => {
            const { data } = res;
            setProviderId(data.provider_id);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      }
    }
  }, []);

  const handleRegistrationTypeChange = (event) => {
    setRegistrationType(event.target.value);
  };
  const handleSelectDiagnosis = (id) => {
    if (selectedDiagnoses.includes(id)) {
      setSelectedDiagnoses(selectedDiagnoses.filter(d => d != id))
    } else {
      setSelectedDiagnoses([...selectedDiagnoses, id])
    }
  }
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleClinicNameChange = (event) => {
    const chosenClinicName = event.target.value;
    setSelectedClinicName(chosenClinicName);

    setValue("clinicName", chosenClinicName);

    setClinicLocations(ClinicList[chosenClinicName] || []);
  };

  const [returnedData, setReturnedData] = useState([]);

  const {
    toggleFromSelectedList,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  useEffect(() => {
    setOnboardingPercent(10);
    if (location.search.length > 0) {
      setValue("email", url.get("email"));
      setValue("firstName", url.get("first"));
      setValue("lastName", url.get("last"));
      if (isProviderForm) {
        const data = { token };
        getProviderTokenInformation({ data })
          .then((res) => {
            const { data } = res;
            // setValue("email", data.providerEmail);
            // Assuming we are hiding the email field for providers in the JSX
            setProviderEmail(data.providerEmail);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      }
    }
  }, []);

  useEffect(() => {
    basicInfoFormFill();
  }, []);

  const basicInfoFormFill = async () => {
    try {
      /* retrieve provider types from DB */
      getProviderTypes().then((res) => {
        const { data } = res;
        setProviderTypes(data);
      });

    } catch {
      history.push("/oops");
    }
  };

  const onSubmit = async (data) => {
    if (!agreed) {
      setReadAlert(true);
      return;
    }
    let patientType;
    let patientSubType;
    if (data.userType == "patient") {
      patientType = 'Other';
      patientSubType = 'Autism';
      data.patientType = patientType;
      data.patientSubType = patientSubType;
    }
    if (providerEmail) {
      data.email = providerEmail;
    }
    if (isProviderForm) {
      if (token.substring(0, 9) == "caregiver") {
        data.referral = "caregiver";
      } else if (token.substring(0, 7) == "patient") {
        data.referral = "patient";
      }
      data.userType = "provider";
    }

    try {
      setIsLoading(true);
      if (data.userType === "provider" || isProviderForm) {
        data.providerToken = token;
        data.messagePolicy = messagePolicy;
        data.alertPolicy = alertPolicy;
      }

      data.email = data.email.toLowerCase();

      const registerUserResponse = await registerUser(data);
      const responseUserData = registerUserResponse.data;
      setUser({ ...responseUserData, patientSubType, patientType });
      const { firstName, lastName, email, userType, userId } = responseUserData;

      if (userType !== 'provider') {
        await sendConfirmEmail({
          email: email,
          name: firstName + " " + lastName,
        });
      }

      await checkExistingList({
        email: email,
      });

      if (userType === "caregiver") {
        history.push("/RegisterPatient", {
          fromPatientControl: false,
        });
      } else if (
        (userType == "patient" && patientType == "Dementia") ||
        patientType == "Other"
      ) {
        // For those are not tbi patients
        data.relationshipType = "self";
        // data.status = combinedValues;
        const registerPatientResponse = await registerPatient(data);
        const responsePatientData = registerPatientResponse.data;
        setPatient(responsePatientData);
        postDiagnoses({ data: selectedDiagnoses, patientId: responsePatientData.patientId });
        if (isInvitedPatientByProvider) {
          await connectInvitedPatientWithP({
            patientId: responsePatientData.patientId,
            userId: userId,
            providerId: providerId,
          });
        }

        history.push("/laterSymptoms", {
          fromPatientControl: location.state?.fromPatientControl,
        })
      } else { //provider
        try {
          const response = await verifyLicenseKey({ userId: userId, licenseKey: "SALLIEFREEACCOUNT" });
          if (response.status === 200) {
            console.log('setVerified is true');
          } else {
            console.error('oops error with provider license ' + userId);
          }
        } catch (error) {
          console.error('Error verifying license key. ' + userId);
        }
        history.push("/patientControl"); // take the provider to patient and caregiver selector screen
      }
    } catch (err) {
      console.log(err);
      if (err.message && err.message.includes("409")) {
        setError("email", {
          type: "emailTaken",
          message: "Email is already taken!",
        });
      } else if (err.message && err.message.includes("406")) {
        setError("email", {
          type: "emailNotValid",
          message: "This is not valid email.",
        });
      } else {
        history.push("/oops");
      }
    } finally {
      setIsLoading(false);
    }
  };

  let SallieMsg = BASIC_INFORMATION[language];

  if (
    errors.dob &&
    errors.dob.message &&
    errors.dob.message === "You must be 13 or older"
  ) {
    SallieMsg = UNDER_13[language];
  }

  return (
    <>
      {/* displays terms and conditions, and hides registration form */}
      <div style={{ display: viewPolicyPatient ? "block" : "none" }}>
        <PrivacyPagePatient
          firstName={firstName}
          lastName={lastName}
          goback={() => {
            setViewPolicyPatient(false);
          }}
          onClick={() => {
            setAgreed(true);
            setViewPolicyPatient(false);
          }}
        />
      </div>

      {/* displays registration form, and hides terms and conditions */}
      <div style={{ display: viewPolicyPatient ? "none" : "block" }}>
        <OnboardingStepHeader text={STEP_1A[language]} />
        <Sallie text={SallieMsg} />
        <Form
          autoComplete="on"
          action="/post"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Container>
            <Row className='mt-3'>
              <Col md={12}>
                <Form.Group controlId="userType">
                  <Row>
                    <Col>
                      <Form.Label>
                        {REGISTRATION_TYPE[language].label}
                        <span className="required">*</span>
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <Form.Check
                      type="radio"
                      label={REGISTRATION_TYPE[language].patient}
                      name="userType"
                      value="patient"
                      checked={registrationType === "patient"}
                      onChange={handleRegistrationTypeChange}
                      id="registrationTypePatient"
                      className="registration-type-radio"
                      ref={register}
                      isInvalid={errors.userType}
                    />
                    <Form.Check
                      type="radio"
                      label={REGISTRATION_TYPE[language].caregiver}
                      name="userType"
                      value="caregiver"
                      checked={registrationType === "caregiver"}
                      onChange={handleRegistrationTypeChange}
                      id="registrationTypeCaregiver"
                      className="registration-type-radio"
                      ref={register}
                      isInvalid={errors.userType}
                    />
                    <Form.Check
                      type="radio"
                      label={REGISTRATION_TYPE[language].provider}
                      name="userType"
                      value="provider"
                      checked={registrationType === "provider"}
                      onChange={handleRegistrationTypeChange}
                      id="registrationTypeProvider"
                      className="registration-type-radio"
                      ref={register}
                      isInvalid={errors.userType}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                        {errors.userType && errors.userType.message}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            {(registrationType === "patient") && (
              <Row>
                <Col>
                  Patient Diagnoses<span className="required">*</span>
                </Col>
                <Col className='mt-3'>
                  <DiagnosisSelector
                    items={diagnoses}
                    selectedItems={selectedDiagnoses}
                    selectItem={handleSelectDiagnosis}
                  />
                </Col>
              </Row>
            )}

            {registrationType === "provider" && (
              <Row>
                <Col md={12}>
                  <Form.Group controlId="providerType">
                    <Form.Label>
                      {PROVIDER_TYPE[language].label} <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      autoFocus={true}
                      name="providerType"
                      as="select"
                      ref={register}
                      isInvalid={errors.providerType}
                    >
                      <option value="">Select a provider type</option>
                      {providerTypes.map((providerType) => (
                        <option
                          key={providerType.constantString}
                          value={providerType.constantString}
                        >
                          {PROVIDER_TYPE[language][providerType.constantString]}
                        </option>
                      ))
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                      {errors.providerType && errors.providerType.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            )}

            {(registrationType !== 'provider') &&
              (<>
                <Row noGutters>
                  <Col md={3} className="pr-2">
                    <LabelledInput
                      name="firstName"
                      required
                      label={NAME[language].first}
                      autoComplete="given-name"
                      onChange={handleFirstNameChange}
                      inputRef={register}
                      errors={errors}
                    />
                  </Col>

                  <Col md={3} className="pr-2">
                    <LabelledInput
                      name="lastName"
                      autoComplete="family-name"
                      label={NAME[language].last}
                      onChange={handleLastNameChange}
                      required
                      inputRef={register}
                      errors={errors}
                    />
                  </Col>

                  {!isProviderForm && (
                    <Col md={6} >
                      <LabelledInput
                        name="email"
                        type="email"
                        label={EMAIL[language]}
                        autoComplete="username"
                        required
                        inputRef={register}
                        errors={errors}
                      />
                    </Col>
                  )}
                </Row>
                <Row noGutters>
                  <Col md={6} className="pr-2">
                    <LabelledInput
                      name="dob"
                      type="DOB"
                      label={DOB[language]}
                      required
                      inputRef={register}
                      errors={errors}
                    />
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="visitType">
                      <Form.Label>
                        {VISIT_TYPE[language].label}
                        <span className='required'>*</span>
                      </Form.Label>
                      <Form.Control as="select" name="visitType" ref={register}>
                        <option value="">Select a visit type</option>
                        <option value="in_person">{VISIT_TYPE[language].in_person}</option>
                        <option value="virtual">{VISIT_TYPE[language].virtual}</option>
                        <option value="hybrid">{VISIT_TYPE[language].hybrid}</option>
                      </Form.Control>
                      {errors.visitType && (
                        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                          {errors.visitType.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </>)
            }

            {(registrationType === 'provider') &&
              (<>
                <Row noGutters>
                  <Col md={6} className="pr-2">
                    <LabelledInput
                      name="firstName"
                      required
                      label={NAME[language].first}
                      autoComplete="given-name"
                      onChange={handleFirstNameChange}
                      inputRef={register}
                      errors={errors}
                    />
                  </Col>

                  <Col md={6}>
                    <LabelledInput
                      name="lastName"
                      autoComplete="family-name"
                      label={NAME[language].last}
                      onChange={handleLastNameChange}
                      required
                      inputRef={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Row noGutters>
                  <Col md={6} className="pr-2" >
                    <LabelledInput
                      name="email"
                      type="email"
                      label={EMAIL[language]}
                      autoComplete="username"
                      required
                      inputRef={register}
                      errors={errors}
                    />
                  </Col>
                  <Col md={6}>
                    <LabelledInput
                      name="dob"
                      type="DOB"
                      label={DOB[language]}
                      required
                      inputRef={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </>)
            }

            <Row noGutters>
              <Col md={6} className="pr-2">
                <Form.Group controlId="clinicName">
                  <Form.Label>
                    {CLINIC[language].clinic_name}
                    <span className='required'>*</span>
                  </Form.Label>
                  <Form.Control
                    name="clinicName"
                    as="select"
                    ref={register}
                    value={selectedClinicName}
                    onChange={handleClinicNameChange}
                  >
                    {Object.keys(ClinicList)
                      .sort()
                      .map((clinicName) => (
                        <option
                          key={clinicName}
                          value={clinicName}
                        >
                          {clinicName}
                        </option>
                      ))}
                  </Form.Control>
                  {errors.clinicName && (
                    <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                      {errors.clinicName.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="clinicLocation">
                  <Form.Label>
                    {CLINIC[language].clinic_location}
                    <span className='required'>*</span>
                  </Form.Label>
                  <Form.Control
                    name="clinicLocation"
                    as="select"
                    ref={register}
                  >
                    <option value="">Select a clinic location</option>
                    {clinicLocations.map((location, index) => (
                      <option key={index} value={location}>
                        {location}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.clinicLocation && (
                    <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                      {errors.clinicLocation.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row noGutters>
              <Col md={6} className="pr-2">
                <Form.Group controlId="country">
                  <Form.Label>
                    {COUNTRY[language]}
                    <span className='required'>*</span>
                  </Form.Label>
                  <Form.Control
                    name="country"
                    as="select"
                    ref={register}
                    defaultValue="US"
                  >
                    {Object.keys(CountryList)
                      .sort()
                      .map((countryName) => (
                        <option
                          key={CountryList[countryName]}
                          value={CountryList[countryName]}
                        >
                          {countryName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={6}>
                <LabelledInput
                  name="postalCode"
                  autoComplete="postal-code"
                  label={ZIPCODE[language]}
                  required
                  inputRef={register}
                  type="text"
                  errors={errors}
                />
              </Col>
            </Row>

            <Row noGutters>
              <Col md={6} className="pr-2">
                <LabelledInput
                  name="password"
                  type="password"
                  label={PASSWORD[language].password}
                  required
                  autoComplete="new-password"
                  inputRef={register}
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <LabelledInput
                  type="password"
                  name="passwordConfirmation"
                  label={PASSWORD[language].confirm_password}
                  autoComplete="new-password"
                  required
                  inputRef={register}
                  errors={errors}
                />
              </Col>
            </Row>

            {(registrationType == "provider" ||
              isProviderForm) && (
                <Row>
                  <PolicyComponent
                    label={MESSAGES_AND_ALERTS[language].messages}
                    radioName="radio-type-1"
                    idYes="field-yes-1"
                    idNo="field-no-1"
                    policyValue={messagePolicy}
                    setPolicyValue={setMessagePolicy}
                  />
                </Row>
              )}
            {(registrationType == "provider" ||
              isProviderForm) && (
                <Row>
                  <PolicyComponent
                    label={MESSAGES_AND_ALERTS[language].alerts}
                    radioName="radio-type-2"
                    idYes="field-yes-2"
                    idNo="field-no-2"
                    policyValue={alertPolicy}
                    setPolicyValue={setAlertPolicy}
                  />
                </Row>
              )}

            <Row>
              <Col md={12} className="terms-and-conditions">
                <span>{TERMS_AND_CONDITIONS[language].accept} </span>
                <LinkButton type="button" onClick={() => setViewPolicyPatient(true)}>
                  <h5>{TERMS_AND_CONDITIONS[language].terms_and_conditions}</h5>
                </LinkButton>
                {readAlert && !agreed && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    Please accept the terms and conditions.
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <SubmitButton disabled={isLoading} type="submit">
                  {SAVE_AND_CONTINUE[language]} &gt;
                </SubmitButton>
              </Col>
            </Row>
          </Container>
        </Form>
      </div >
    </>
  );
};

export default BasicInfo;
