import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import TherapyTracker from "./TherapyTracker"

import {
    getUserTherapyList,
    addUserTherapy,
    pauseUserTherapy,
    unpauseUserTherapy,
    deleteUserTherapy,
    recordUserTherapy
} from "../../../api/TBIRequests";

const TherapyTrackerContainer = ({ patientId, date }) => {
    const [therapyList, setTherapyList] = useState([]);
    const [pausedTherapyList, setPausedTherapyList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newTherapyName, setNewTherapyName] = useState("");

    useEffect(() => {
        const fetchUserTherapyList = async () => {
            try {
                const formattedDate = format(date, "yyyy-MM-dd");
                const response = await getUserTherapyList({ patientId, log_date: formattedDate });
                const therapies = response.data || [];

                const activeTherapies = therapies.filter(therapy => !therapy.is_paused);
                const pausedTherapies = therapies.filter(therapy => therapy.is_paused);

                setTherapyList(activeTherapies);
                setPausedTherapyList(pausedTherapies);
            } catch (error) {
                console.error("Error fetching therapies:", error);
            }
        };

        fetchUserTherapyList();
    }, [date]);

    const handleAddTherapy = async () => {
        if (newTherapyName.trim() === "") return;
        try {
            const response = await addUserTherapy({ patientId }, { therapy_name: newTherapyName });
            const newTherapy = response.data;
            setTherapyList(prev => [...prev, newTherapy]);
            setNewTherapyName("");
            setShowModal(false);
        } catch (error) {
            console.error("Error adding therapy:", error);
        }
    };

    const handlePauseTherapy = async (therapyId) => {
        try {
            await pauseUserTherapy({ patientId }, { therapy_id: therapyId });

            setTherapyList((prevTherapies) => {
                return prevTherapies.filter((therapy) => therapy.therapy_id !== therapyId);
            });

            setPausedTherapyList((prevPaused) => {
                const therapyToPause = therapyList.find(therapy => therapy.therapy_id === therapyId);
                return therapyToPause ? [...prevPaused, { ...therapyToPause, is_paused: true }] : prevPaused;
            });
        } catch (error) {
            console.error("Error pausing therapy:", error);
        }
    };

    const handleUnpauseTherapy = async (therapyId) => {
        try {
            await unpauseUserTherapy({ patientId }, { therapy_id: therapyId });

            setPausedTherapyList((prevPaused) => {
                return prevPaused.filter((therapy) => therapy.therapy_id !== therapyId);
            });

            setTherapyList((prevTherapies) => {
                const therapyToUnpause = pausedTherapyList.find(therapy => therapy.therapy_id === therapyId);
                return therapyToUnpause ? [...prevTherapies, { ...therapyToUnpause, is_paused: false }] : prevTherapies;
            });
        } catch (error) {
            console.error("Error unpausing therapy:", error);
        }
    };

    const handleDeleteTherapy = async (therapyId) => {
        try {
            await deleteUserTherapy({ patientId }, { therapy_id: therapyId });
            setTherapyList(prevTherapies => prevTherapies.filter(therapy => therapy.therapy_id !== therapyId));
            setPausedTherapyList(prevPaused => prevPaused.filter(therapy => therapy.therapy_id !== therapyId));
        } catch (error) {
            console.error("Error deleting therapy:", error);
        }
    };

    const handleRecordTherapy = async (therapyId, therapyName, count) => {
        try {
            const formattedDate = format(date, "yyyy-MM-dd");
            await recordUserTherapy({ patientId }, { therapy_id: therapyId, therapy_name: therapyName, session_count: count, log_date: formattedDate });
            setTherapyList(prevTherapies =>
                prevTherapies.map(therapy =>
                    therapy.therapy_id === therapyId
                        ? { ...therapy, session_count: count }
                        : therapy
                )
            );
        } catch (error) {
            console.error("Error recording therapy:", error);
        }
    };

    return (
        <TherapyTracker
            therapyList={therapyList}
            pausedTherapyList={pausedTherapyList}
            showModal={showModal}
            setShowModal={setShowModal}
            newTherapyName={newTherapyName}
            setNewTherapyName={setNewTherapyName}
            handleAddTherapy={handleAddTherapy}
            handlePauseTherapy={handlePauseTherapy}
            handleUnpauseTherapy={handleUnpauseTherapy}
            handleDeleteTherapy={handleDeleteTherapy}
            handleRecordTherapy={handleRecordTherapy}
        />
    )
}
export default TherapyTrackerContainer;