import React, { useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { FEELING_TODAY } from "../../constants/DashboardTranslation";

import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ComponentWrapper from "./ComponentWrapper";
import FeelingGood from "../../images/feeling-good.svg";
import FeelingNeutral from "../../images/feeling-neutral.svg";
import FeelingBad from "../../images/feeling-bad.svg";
import SubmitButton from "../StyledComponents/SubmitButton";

import { postFeelingToday } from "../../api/TBIRequests";
import { Button } from "react-bootstrap";

const FeelingToday = ({ setDoneSimpleTracking }) => {
  const { language } = useContext(PreferredLanguageContext);
  const { patient } = useContext(PatientContext);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [mood, setMood] = useState(5);

  const date = new Date();

  const submitFeeling = (num) => {
    setSubmitting(true);
    const feelingData = {
      severity: num,
      date: date,
    };
    postFeelingToday({
      patientId: patient.patientId,
      data: feelingData,
    })
      .then(() => {
        setDoneSimpleTracking(true);
        setError(false);
        setSubmitting(false);
      })
      .catch(() => {
        setError(true);
        setSubmitting(false);
      });
  };

  const renderLabels = () => {
    const labels = [];
    for (let i = 0; i <= 10; i += 1) {
      labels.push(
        <span key={i} style={{ position: 'absolute', left: `${0.5 + i * 9.7}%`}}>
          {i}
        </span>
      );
    }
    return labels;
  };

  const handleKeyPress = (e, num) => {
    if (e.key === "Enter" || e.key === " ") {
      submitFeeling(num);
    }
  };

  const imgStyle = { cursor: "pointer" };

  return submitting ? (
    <ComponentWrapper>{FEELING_TODAY[language].submit}</ComponentWrapper>
  ) : (
    <ComponentWrapper>
      <Col md={12} className="text-center align-items-lg-center">
        {error && (
          <>
            <div>
              {FEELING_TODAY[language].error[0]}{" "}
              <Link to="/help">{FEELING_TODAY[language].error[1]}</Link>
              {FEELING_TODAY[language].error[2]}
            </div>
          </>
        )}
        <div className="ml-2">
          {FEELING_TODAY[language].label}
        </div>
        <div style={{ width: '100%' }}>
          <input
            type="range"
            min={0}
            max={10}
            step={1}
            value={mood}
            onChange={event => setMood(event.target.value)}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ position: 'relative', paddingBottom: "30px", width: '100%'}}>
          {renderLabels()}
        </div>
        <div style={{ position: 'relative', paddingBottom: "30px", width: '100%'}}>
          <span key={"no-problems"} style={{ position: 'absolute', left: `0%` }}>
            {FEELING_TODAY[language].mood.good}
          </span>
          <span key={"very-bad"} style={{ position: 'absolute', right: `0%` }}>
            {FEELING_TODAY[language].mood.bad}
          </span>
        </div>
        <SubmitButton
          style={{ height: "max-content"}}
          onClick={() => {
            submitFeeling((10 - mood) * 10)
          }}
          type="button"
          centered={true}
          disabled={submitting}
        >
          {FEELING_TODAY[language].save}
        </SubmitButton>
      </Col>
    </ComponentWrapper>
  );
};

export default FeelingToday;
