import React, { useState, useEffect, useContext } from "react";
import { ThemeContext, PatientContext, PreferredLanguageContext } from "../lib/main-context";
import { CHARTS, EXPANDED_CHART, EXPORT_PDF, GO_BACK, INTENSITY_LEVEL, OFF_DARK_MODE_EXPORT } from "../constants/DashboardTranslation";

import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { parse } from "date-fns";
import Form from "react-bootstrap/Form";

import theme from "../index.scss";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import LineChartOptions from "./DashboardComponents/LineChartOptions";
import SubmitButton from "./StyledComponents/SubmitButton";
import ModalPdf from "./DashboardComponents/ModalPdf";
import DoughnutTrigger from "./DoughnutTrigger";
import DoughnutMedical from "./DoughnutMedical";
import TriggerBarChart from "./TriggerBarChart";
import MedicalBarChart from "./MedicalBarChart";
import FeelingBar from "./FeelingBar";

import fillInDays from "../lib/fillInDates";
import getDaysAgo from "../lib/getDaysAgo";
import triggerWithSetVal from "../lib/triggerWithSetVal";

import { getAdditionalNote } from "../api/TBIRequests";

const ExpandedChart = ({
  error,
  topSymptoms,
  data,
  retry,
}) => {
  const location = useLocation();
  const history = useHistory();
  const days = location.state?.days || 30;

  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const [assignedDays, setAssignedDays] = useState(days);
  const [additionalNotes, setAdditionalNotes] = useState([]);
  const [newData2, setNewData2] = useState({});
  const [triggerByDays, setTriggerByDays] = useState([]);
  const [showLineModal, setShowLineModal] = useState(false);
  const [startDate, setStartDate] = useState("");

  const medicalData = data
    ?.filter((obj) => obj.category !== "SDOH")
    .filter(
      (obj) =>
        parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) >
        getDaysAgo(assignedDays)
    );

  const triggerData = data
    ?.filter((obj) => obj.category == "SDOH")
    .filter(
      (obj) =>
        parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) >
        getDaysAgo(assignedDays)
    );

  const colors = [
    "#fdfdfd", "#1d1d1d", "#ebce2b", "#702c8c", "#db6917", "#96cde6", "#ba1c30",
    "#c0bd7f", "#7f7e80", "#5fa641", "#d485b2", "#4277b6", "#df8461", "#463397",
    "#e1a11a", "#91218c", "#e8e948", "#7e1510", "#92ae31", "#6f340d", "#d32b1e",
    "#2b3514"
  ];

  const handleLineModalClose = () => setShowLineModal(false);
  const handleLineModalShow = () => setShowLineModal(true);

  useEffect(() => {
    getAdditionalNote(patient.patientId)
      .then(({ data }) => {
        setAdditionalNotes(data);
      })
      .catch((e) => console.log("getAdditionalNotesError", e));
  }, []);

  let filterDataByDays = (dataset) => {
    const datasetConvertedToChartData = dataset.map((item) => {
      return {
        // CURRENT BUG, Must remove time stamp or labels do not work correctly
        x: item.symptom_date.slice(0, 10),
        y: item.severity,
        desc: item.description,
      };
    });

    const filteredByDays = datasetConvertedToChartData.filter(
      (item) =>
        parse(item.x, "yyyy-MM-dd", new Date()) > getDaysAgo(assignedDays)
    );
    return filteredByDays;
  };

  let datasetArr = topSymptoms.map((symptom) => {
    return data.filter(({ factor }) => factor === symptom.factor);
  });

  datasetArr.push(triggerByDays);

  let newData = {
    datasets: [],
    notes: additionalNotes,
  };
  let { datasets } = newData;

  datasetArr.forEach((dataset, index) => {
    const isLastDataset = index === datasetArr.length - 1;
    const datasetData = filterDataByDays(dataset);

    datasets.push({
      label: isLastDataset ? "Daily Activities" : topSymptoms[index].factor,
      data: datasetData,
      pointStyle: isLastDataset ? "triangle" : undefined,
      type: isLastDataset ? "scatter" : undefined,
      backgroundColor: isLastDataset ? theme.primary : colors[index],
      borderColor: isLastDataset ? theme.primary : colors[index],
      borderWidth: 1,
      fill: isLastDataset ? false : undefined,
      hoverBackgroundColor: isLastDataset ? theme.primary : colors[index],
      hoverBorderColor: "white",
    });
  });

  let ChartDataset = fillInDays(datasets, assignedDays);
  newData.datasets = ChartDataset;

  let newDataC = {
    datasets: [],
    notes: additionalNotes,
  };
  // let { datasets } = newDataC;

  let ChartDatasetC = fillInDays(newDataC.datasets, assignedDays);
  newDataC.datasets = ChartDatasetC;

  useEffect(() => {
    datasetArr = topSymptoms.map((symptom) => {
      return data.filter(({ factor }) => factor === symptom.factor);
    });

    const triggerList = triggerWithSetVal(data);
    const triggerByDays = triggerList.filter(
      (obj) =>
        parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) >
        getDaysAgo(assignedDays)
    );
    setTriggerByDays(triggerByDays);
    datasetArr.push(triggerList);

    datasets = [];
    datasetArr.forEach((dataset, index) => {
      const isLastDataset = index === datasetArr.length - 1;
      const datasetData = filterDataByDays(dataset);

      datasets.push({
        label: isLastDataset ? "Daily Activities" : topSymptoms[index].factor,
        data: datasetData,
        pointStyle: isLastDataset ? "triangle" : undefined,
        type: isLastDataset ? "scatter" : undefined,
        backgroundColor: isLastDataset ? theme.primary : colors[index],
        borderColor: isLastDataset ? theme.primary : colors[index],
        borderWidth: 1,
        fill: false,
        hoverBackgroundColor: isLastDataset ? theme.primary : colors[index],
        hoverBorderColor: "white",
      });
    });

    ChartDataset = fillInDays(datasets, assignedDays);
    const firstDay = getDaysAgo(assignedDays - 1);
    const formattedStartDate = firstDay?.toString().substring(0, 15);
    setStartDate(formattedStartDate);

    newData.datasets = ChartDataset;
    setNewData2(newData);

    ChartDatasetC = fillInDays(newDataC.datasets, assignedDays);

    newDataC.datasets = ChartDatasetC;
  }, [assignedDays]);

  const extendedChartOption1 = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: `${assignedDays} ${EXPANDED_CHART[language].days_in_review}`,
    },
    fontFamily: "quicksand",
    fontStyle: "bold",
    fontSize: "20",
    fontColor: dark ? theme.darkModeText : theme.black,
    legend: {
      labels: {
        fontColor: dark ? theme.darkModeText : theme.black,
        usePointStyle: true,
        boxWidth: 8,
      },
    },
  }

  const colorOverRide = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "day",
            tooltipFormat: "dddd",
          },
          ticks: {
            fontColor: dark ? theme.darkModeText : theme.black,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: dark ? theme.darkModeText : theme.black,
          },
          scaleLabel: {
            display: true,
            labelString: INTENSITY_LEVEL[language],
            fontColor: dark ? theme.darkModeText : theme.black,
          },
        },
      ],
    },
  };

  return (
    <>
      {error && (
        <ComponentWrapper>
          {CHARTS[language].error[0]}
          <SubmitButton onClick={retry}>{CHARTS[language].error[1]}</SubmitButton>
        </ComponentWrapper>
      )}
      <ComponentWrapper>
        <Row className="d-flex justify-content-around">
          <Form.Group controlId="formBasicSelect">
            <Form.Label>{EXPANDED_CHART[language].days_since} {startDate} </Form.Label>
            <Form.Control
              as="select"
              // value={date}
              defaultValue={assignedDays}
              onChange={(e) => {
                setAssignedDays(e.target.value);
              }}
            >
              <option value={5}>{`5 ${EXPANDED_CHART[language].days_in_review}`}</option>
              <option value={30}>{`30 ${EXPANDED_CHART[language].days_in_review}`}</option>
              {/* <option value={60}>{`60 ${EXPANDED_CHART[language].days_in_review}`}</option> */}
              <option value={90}>{`90 ${EXPANDED_CHART[language].days_in_review}`}</option>
              <option value={120}>{`120 ${EXPANDED_CHART[language].days_in_review}`}</option>
            </Form.Control>
          </Form.Group>

          <SubmitButton
            onClick={() => {
              if (dark) {
                alert(OFF_DARK_MODE_EXPORT[language]);
              } else {
                handleLineModalShow();
              }
            }}
          >
            {EXPORT_PDF[language]}
          </SubmitButton>
        </Row>
        <div className="row flex-column justify-content-center align-items-center">
          <div className="row" style={{ marginBottom: "10px" }}>
            {!patient.caregiverName &&
              EXPANDED_CHART[language].report_detail.patient +
              patient.firstName + " " + patient.lastName
            }
          </div>
        </div >
      </ComponentWrapper >
      <ModalPdf
        isReport={true}
        show={showLineModal}
        handleClose={handleLineModalClose}
        chart={[
          `trigger-doughnut`,
          `medical-doughnut`,
          `trigger-barChart`,
          `medical-barChart`,
          `feeling-bar`,
          `${assignedDays}day-lineChart`,
        ]}
        data={data}
        additionalNotes={additionalNotes}
        days={assignedDays}
      />
      <Row>
        <Col md={6}>
          <ComponentWrapper>
            <div className={`trigger-doughnut`}>
              <DoughnutTrigger
                data={triggerData}
                days={assignedDays}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>
        <Col md={6}>
          <ComponentWrapper>
            <div className={`medical-doughnut`}>
              <DoughnutMedical
                data={medicalData}
                days={assignedDays}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ComponentWrapper>
            <div className={`trigger-barChart`}>
              <TriggerBarChart
                data={triggerByDays}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>
        <Col md={6}>
          <ComponentWrapper>
            <div className={`medical-barChart`}>
              <MedicalBarChart
                data={medicalData}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ComponentWrapper>
            <div className={`feeling-bar`}>
              <FeelingBar
                data={medicalData}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>
        <Col md={8}>
          <ComponentWrapper>
            <div className={`${assignedDays}day-lineChart`}>
              <Line
                data={newData2}
                width={100}
                height={500}
                options={{
                  ...LineChartOptions,
                  ...extendedChartOption1,
                  ...colorOverRide,
                  legend: {
                    position: "bottom",
                    labels: {
                      usePointStyle: true,
                      boxWidth: 8,
                      generateLabels: function (chart) {
                        return chart.data.datasets.map((dataset, index) => {
                          const label = dataset.label.length > 30
                            ? dataset.label.slice(0, 30) + "..."
                            : dataset.label;

                          return {
                            text: label,
                            fullText: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            hidden: !chart.isDatasetVisible(index),
                            datasetIndex: index,
                          };
                        });
                      },
                    },
                    onHover: function (event, legendItem) {
                      const tooltip = document.getElementById("legend-tooltip");
                      tooltip.style.display = "block";
                      tooltip.style.left = `${event.clientX - 1200}px`;
                      tooltip.style.top = `${event.clientY - 100}px`;
                      tooltip.innerHTML = legendItem.fullText;
                    },
                    onLeave: function () {
                      const tooltip = document.getElementById("legend-tooltip");
                      tooltip.style.display = "none";
                    },
                  },
                }}
              />
            </div>
            <div
              id="legend-tooltip"
              style={{
                position: "absolute",
                display: "none",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                color: "#fff",
                padding: "5px",
                borderRadius: "4px",
                fontSize: "12px",
                pointerEvents: "none",
                whiteSpace: "nowrap",
              }}
            ></div>
          </ComponentWrapper>
        </Col>
      </Row>
      <SubmitButton onClick={() => history.push("/")}>{GO_BACK[language]}</SubmitButton>
    </>
  );
};

export default ExpandedChart;
