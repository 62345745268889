import React, { useState, useEffect } from "react";

import { getProviderList } from "../../api/messageRequests";
import { getUserProviderType, getAssignedProvider, updateAssignedProvider } from "../../api/TBIRequests";

import Select from "react-select";

const AssignmentButton = ({ userId, patientId }) => {
    const [providerList, setProviderList] = useState([]);
    const [userProviderType, setUserProviderType] = useState(null);
    const [assignedProvider, setAssignedProvider] = useState(null);

    useEffect(() => {
        const fetchProviderList = async () => {
            try {
                const res = await getProviderList();
                const providers = res.data
                const formattedProviders = providers.map(provider => ({
                    value: provider.provider_id,
                    label: provider.full_name
                }));
                setProviderList(formattedProviders);
            } catch (err) {
                console.error('Error fetching provider list', err)
            }
        }

        const fetchUserProviderType = async () => {
            try {
                const res = await getUserProviderType({ userId });
                setUserProviderType(res.data.type);
            } catch (err) {
                console.error('Error fetching user provider type', err)
            }
        }
        fetchProviderList();
        fetchUserProviderType();
    }, []);

    useEffect(() => {
        const fetchAssignedProvider = async () => {
            try {
                const res = await getAssignedProvider({ patientId });
                if (res.data && res.data.assigned_provider_id) {
                    const matchingProvider = providerList.find(
                        (provider) => provider.value === res.data.assigned_provider_id
                    )
                    setAssignedProvider(matchingProvider);
                }
            } catch (err) {
                console.error('Error fetching assigned provider', err)
            }
        }
        fetchAssignedProvider();
    }, [patientId, providerList])

    const handleChange = async (selectedOption) => {
        setAssignedProvider(selectedOption);
        try {
            await updateAssignedProvider({ patientId }, { assignedProviderId: selectedOption.value });
        } catch (err) {
            console.error("Error updating assigned provider:", err);
        }
    };

    const isDropdownDisabled = !(
        userProviderType === 'operations' || userProviderType === 'administrativeSupport'
    )

    return (
        <>
            <h6>Assigned Provider</h6>
            <Select
                options={providerList}
                placeholder='No Assigned Provider'
                value={assignedProvider}
                onChange={handleChange}
                isDisabled={isDropdownDisabled}
            />
            {isDropdownDisabled &&
                <p className='required mt-2'>
                    This setting may only be modified by the Operations or Administrative Support team.
                </p>
            }
        </>
    )
}
export default AssignmentButton;